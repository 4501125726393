<template>
  <div class="order">
    <div class="passwordWrapper">
      <p>填写密码:</p>
      <van-field
        v-model="passwordMessage"
        rows="3"
        autosize
        type="textarea"
        placeholder="请输入密码信息"
        show-word-limit
      />
    </div>
    <div class="orderWrapper">
      <p class="record">近15天记录 ({{recordList.length}}条)</p>
      <div class="orderList">
        <ul>
          <li v-for="(item,index) in recordList" :key="index">
            <div class="left">
              <h2>{{item.modeldata.name}}</h2>
              <p>{{item.addedTime|formatterTimeYMDHMS}}（近{{item.dayNums}}天打开）</p>
            </div>
            <div class="right">
              <van-button type="info" size="small" @click="openScene(index)">打开</van-button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="btnWrapper">
      <van-button type="info">使用上次订单</van-button>
      <van-button type="info" @click="ensure">确认</van-button>
    </div>
  </div>
</template>

<script>
import myDB from '@/assets/js/indexedDB.js'
import formatterTime from '@/assets/js/formatterTime.js'
export default {
  data() {
    return {
      passwordMessage: "",
      recordList:[]
    };
  },
  mounted(){
    window.myDB = myDB
    //初始化indexDB
    myDB.openDB("modelData",1,{name:'model',keyPath:'panelId'},this.initIndexDB)
  },
  methods:{

    initIndexDB: async function(db){
      //读取所有
      let newList = [];
      let result = await myDB.readAll(db,'model')
      result.forEach(item=>{
        let dayNums = formatterTime(item.addedTime);
        //删除大于15天的数据
        if(dayNums>15){
          myDB.deleteData(db,'model',item.panelId)
        }else{
          console.log(dayNums)
          item.dayNums = dayNums;
          newList.push(item)
        }
      })
      //最近添加的排前面
      newList.sort((a,b)=>{
        return a.dayNums - b.dayNums
      })
      this.recordList = [...newList]
    },

    //打开历史场景
    openScene:function(){
      this.$router.push({name:'model'})
    },

    //确认
    ensure:function(){
      this.$router.push({name:'work'})
    }
  }
};
</script>

<style lang='less' scoped>
.order {
  color: #ffffff;
  height: 100%;
  padding: 8% 5% 3%;
  box-sizing: border-box;
  background-color: #231f1e;
  display: flex;
  flex-direction: column;
  .passwordWrapper {
    font-size: 30px;
    p {
      margin-bottom: 10px;
    }
  }
  .orderWrapper {
    flex: 1;
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    // border: 1px solid red;
    .record {
      text-align: right;
      font-size: 28px;
      line-height: 50px;
      border-bottom: 1px solid #f2f2f2;
    }
    .orderList {
      flex: 1;
      padding: 0 15px;
      overflow-y: scroll;
      ul li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        h2 {
          font-size: 32px;
          margin-bottom: 8px;
        }
        p {
          font-size: 25px;
        }
      }
    }
  }
  .btnWrapper {
    display: flex;
    button {
      flex: 1;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
</style>
